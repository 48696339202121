import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout/Layout';
import Section from '~/components/Section/Section';
// import HowItWorks from '~/components/HowItWorks/HowItWorks';
import Testimonial from '~/components/Testimonial/Testimonial';
import Container from '~/components/Container/Container';

const IndexPage = ({ data }) => {
  const {
    testimonials,
    sections,
    pages,
    social,
    calendly,
    title
  } = data.site.siteMetadata;
  // const { sections, howItWorks, pages, social, title } = data.site.siteMetadata;
  const meta = pages.home;
  const testimonial = testimonials[0];

  const sectionsNumber = sections ? sections.length : 0;
  const sectionsWithoutBackground = sectionsNumber
    ? sections.slice(0, sectionsNumber - 2)
    : [];
  const sectionsWithBackground = sectionsNumber ? sections.slice(-2) : [];

  return (
    <Layout
      meta={meta}
      socials={social}
      hasContainer={false}
      title={title}
      isHome={true}
      calendly={calendly}
    >
      <Testimonial
        title={testimonial.title}
        quote={testimonial.quote}
        image={testimonial.image}
        author={testimonial.author}
        link={testimonial.link}
        website={testimonial.website}
      />
      <div className="sections">
        {sectionsWithoutBackground.map((section) => (
          <Section
            title={section.title}
            description={section.description}
            points={section.points}
            reversed={section.reversed}
            key={section.title}
            className={`${section.cssClassName} container`}
          />
        ))}

        {sectionsWithBackground.length && (
          <div className="sections-background">
            <Container>
              {sectionsWithBackground.map((section) => (
                <Section
                  title={section.title}
                  description={section.description}
                  points={section.points}
                  reversed={section.reversed}
                  key={section.title}
                  className={section.cssClassName}
                />
              ))}
            </Container>
          </div>
        )}
      </div>

      {/*      <HowItWorks
				items={howItWorks.items}
				title={howItWorks.title}
				description={howItWorks.description}
			/>*/}
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object,
  path: PropTypes.string
};

export default IndexPage;

// eslint-disable-next-line one-var
export const pageQuery = graphql`
  query Homepage {
    site {
      siteMetadata {
        title
        calendly {
          domain
          kubeflow {
            url
            googleAnalytics {
              category
              action
              label
            }
          }
        }
        sections: home {
          title
          description
          points
          reversed
          cssClassName
        }
        testimonials {
          title
          author {
            name
            role
          }
          quote
          image
          website
          link
        }
        howItWorks {
          title
          description
          items {
            description
            image
          }
        }
        pages {
          home {
            title
            description
            keywords
            googleSiteVerification
          }
        }
        social {
          twitter
          github
          linkedin
          email
          feed
        }
      }
    }
  }
`;
