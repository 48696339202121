import React from 'react';
import PropTypes from 'prop-types';
import './Testimonial.css';
import Container from '~/components/Container/Container';

const Testimonial = ({ title, quote, image, author, website, link }) => (
  <div className="testimonial">
    <Container>
      {/*<h2 className="testimonial-title section-title">Testimonials</h2>*/}
      <a href={link}>
        <h2 className="testimonial-title section-title">{title}</h2>
      </a>
      <blockquote className="testimonial-quote">“{quote}”</blockquote>
      {/*<blockquote className="testimonial-quote">“{quote}” <span className="testimonial-author">- {author.name}, {author.role}</span></blockquote>*/}
      <p className="testimonial-author">
        - {author.name}, {author.role}
      </p>
      <a href={website}>
        <img src={image} className="testimonial-image" />
      </a>
    </Container>
  </div>
);

Testimonial.propTypes = {
  author: PropTypes.object,
  title: PropTypes.string,
  quote: PropTypes.string,
  image: PropTypes.string,
  website: PropTypes.string,
  link: PropTypes.string
};

export default Testimonial;
