import React from 'react';
import PropTypes from 'prop-types';
import Bullet from './../Bullet/Bullet.jsx';

import './Section.css';

const Section = ({ title, description, points, className }) => (
  <div className={`section ${className}`}>
    <div className="section-image" />

    <div className="section-content">
      {title && <h2 className="section-title">{title}</h2>}
      {description && (
        <p
          className="section-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}

      {points && points.length && (
        <ul className="section-list">
          {points.map((point, i) => (
            <Bullet key={i} text={point} />
          ))}
        </ul>
      )}
    </div>
  </div>
);

Section.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  points: PropTypes.array,
  image: PropTypes.object,
  className: PropTypes.string
};

export default Section;
